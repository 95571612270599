const DEFAULT_SIZE = false;

const classnames = require('classnames');

const namespace = 'font-size';

const sizenames = (size, className) => {
  const sizeName = typeof size === 'string' ? size : (size && size.font_size) || DEFAULT_SIZE;
  return classnames({ [`${namespace}--${sizeName}`]: sizeName }, className);
};

module.exports = sizenames;
