/* eslint-disable security/detect-object-injection */
const React = require('react');
const isEmpty = require('lodash/isEmpty');
const MoneyAmount = require('@andes/money-amount');
const StyledLabel = require('../components/commons/styled-label');
const IconFull = require('../components/commons/icons/full');
const HandShake = require('../components/commons/icons/hand-shake');
const IconFactory = require('../components/commons/icons/factory-builder')([
  IconFull,
  HandShake,
]);

const { TYPE_PRICE, TYPE_ICON, INTERPOLATION_REGEXP } = require('../../utils/constants');

/**
 * @param {string} text
 * @param {object} values
 */
const interpolator = (text, values, className = null, accessibilityText = null) => {
  if (isEmpty(values)) {
    return text;
  }
  const result = text.split(INTERPOLATION_REGEXP);
  for (let index = 0; index < result.length; index += 1) {
    const key = result[index];
    if (Object.prototype.hasOwnProperty.call(values, key)) {
      if (accessibilityText) values[key].accessibilityText = accessibilityText;
      const replacement = values[key];
      switch (replacement.type) {
        case TYPE_PRICE: {
          const { symbol, currencyId, fraction, cents } = replacement;
          result[index] = (
            <MoneyAmount
              value={{
                fraction,
                cents,
              }}
              symbol={symbol}
              currencyId={currencyId}
              centsType="superscript"
              size={16}
              compact
              key={index}
            />
          );
          break;
        }
        case TYPE_ICON:
        case TYPE_ICON.toUpperCase():
          result[index] = IconFactory(replacement);
          break;
        default:
          result[index] = <StyledLabel key={replacement.text} className={className} as="span" {...replacement} />;
      }
    }
  }
  return result;
};

module.exports = interpolator;
