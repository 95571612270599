const React = require('react');
const { string } = require('prop-types');
const { Image } = require('nordic/image');
const classnames = require('classnames');

const ICON_ID = 'home-da-fs-nb';
const namespace = 'ui-homes-icon ui-homes-icon--da-new-buyer';

const IconNewBuyer = ({ className, deviceType, alt }) => (
  <Image
    className={classnames(namespace, className)}
    src={`dynamic-access-${deviceType}/new-buyer.svg`}
    alt={alt}
    lazyload="off"
    preload
  />
);

IconNewBuyer.propTypes = {
  className: string,
  deviceType: string,
  alt: string,
};

IconNewBuyer.defaultProps = {
  className: null,
  deviceType: 'desktop',
  alt: '',
};

module.exports = React.memo(IconNewBuyer);
module.exports.ICON_ID = ICON_ID;
