const DEFAULT_SIZE = 24;
const CENTS_TYPE = 'superscript';
module.exports = function modelMoneyAmountCombo(values, size) {
  if (values) {
    if (Object.keys(values).length > 0) {
      const { cents_type, currency_id, value, suffix } = values;
      const { fraction, cents } = value;
      return {
        size: size || DEFAULT_SIZE,
        centsType: cents_type || CENTS_TYPE,
        currencyId: currency_id,
        value: {
          fraction,
          cents,
        },
        suffix,
      };
    }
  }
  return null;
};

