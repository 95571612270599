const sizenames = require('./sizenames');
const colornames = require('./colornames');
const familynames = require('./familynames');
const backgroundColor = require('./background-color');
const textAlignment = require('./text-alignment');
const margins = require('./margins');

const applyClasses = ({ alignment, size, family, color, bg_color, margin, className }) => textAlignment(
  alignment,
  backgroundColor(bg_color?.toUpperCase(), colornames(color, sizenames(size, familynames(family, margins(margin, className))))),
);

module.exports = applyClasses;
