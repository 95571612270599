const React = require('react');
const { string } = require('prop-types');
const { Image } = require('nordic/image');
const classnames = require('classnames');

const ICON_ID = 'home-da-cellphones-category';
const namespace = 'ui-homes-icon ui-homes-icon--da-cellphones-category';

const IconCellphoneCategory = ({ className, deviceType, alt }) => (
  <Image
    className={classnames(namespace, className)}
    src={`dynamic-access-${deviceType}/cellphones-category.svg`}
    alt={alt}
    lazyload="off"
    preload
  />
);

IconCellphoneCategory.propTypes = {
  className: string,
  deviceType: string,
  alt: string,
};

IconCellphoneCategory.defaultProps = {
  className: null,
  deviceType: 'desktop',
  alt: '',
};

module.exports = React.memo(IconCellphoneCategory);
module.exports.ICON_ID = ICON_ID;
