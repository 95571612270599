const React = require('react');
const { string, bool, node, oneOf, oneOfType, shape, number } = require('prop-types');
const applyclasses = require('../../../lib/apply-classes');
const { arrayIncludes } = require('../../../lib/array');
const entries = require('../../../lib/object-entries');

const createMarkup = text => ({ __html: text });

const allowedForwardedProps = ['href', 'target', 'id', 'onClick', 'data-testid'];

const filterForwardProps = (forwardProps = {}) => entries(forwardProps).reduce((acc, [name, value]) => {
  if (arrayIncludes(allowedForwardedProps, name)) {
    // eslint-disable-next-line security/detect-object-injection
    acc[name] = value;
  }
  return acc;
}, {});

// Map from BE props to FE naming
const StyledLabel = ({
  as,
  alignment,
  withDangerous,
  text,
  color,
  bg_color,
  font_family,
  font_size,
  className,
  margin,
  accessibilityText,
  children,
  isAccessibilityHidden,
  ...forwardProps
}) => {
  const classes = applyclasses({
    size: font_size,
    family: font_family,
    alignment,
    color,
    bg_color,
    className,
    margin,
  });
  const Element = as;
  const filteredForwardedProps = filterForwardProps(forwardProps);
  const addAccessibilityHidden = {};

  if (isAccessibilityHidden) {
    addAccessibilityHidden['aria-hidden'] = true;
  }

  if (withDangerous) {
    return (
      <Element
        className={classes}
        {...addAccessibilityHidden}
        {...filteredForwardedProps}
        dangerouslySetInnerHTML={createMarkup(text)}
      />
    );
  }

  return (
    <Element className={classes} {...addAccessibilityHidden} {...filteredForwardedProps}>
      {text}
      {accessibilityText && <span className="andes-visually-hidden">{accessibilityText}</span>}
      {children}
    </Element>
  );
};

StyledLabel.propTypes = {
  as: oneOf(['p', 'span', 'h1', 'h2', 'h3', 'h4', 'li', 'a', 'del', 'div']),
  alignment: oneOf(['center', 'left', 'right']),
  withDangerous: bool,
  bg_color: string,
  className: string,
  text: oneOfType([string, node]),
  margin: shape({
    top: number,
    bottom: number,
    left: number,
    right: number,
  }),
  color: string,
  font_family: string,
  font_size: string,
  accessibilityText: string,
  children: node,
  isAccessibilityHidden: bool,
};

StyledLabel.defaultProps = {
  as: 'p',
  alignment: null,
  withDangerous: false,
  bg_color: null,
  className: null,
  text: null,
  color: null,
  font_family: 'REGULAR',
  font_size: null,
  accessibilityText: null,
  children: null,
  margin: null,
  isAccessibilityHidden: false,
};

module.exports = StyledLabel;
