const internalIncludes = (el, search) => {
  try {
    return el.indexOf(search) !== -1;
  } catch (e) {
    return false;
  }
};

const internalFind = (el, id) => el.find(c => c.id === id);

module.exports = { arrayFind: internalFind, arrayIncludes: internalIncludes, stringIncludes: internalIncludes };
