const {
  trackPage: trackMelidataPage,
  trackEvent: trackMelidataEvent,
  trackEventWithExperiments: trackMelidataEventWithExperiments,
} = require('./melidata');

const trackPage = tracks => {
  if (tracks && tracks.melidata_event) {
    trackMelidataPage(tracks.melidata_event);
  }
};

const trackEvent = tracks => {
  if (tracks && tracks.melidata_event) {
    if (tracks.melidata_event.experiments) {
      trackMelidataEventWithExperiments(tracks.melidata_event);
    } else {
      trackMelidataEvent(tracks.melidata_event);
    }
  }
};

if (global.window && global.window !== undefined) {
  global.window.Tracking = { trackPage, trackEvent };
}

module.exports = {
  trackPage,
  trackEvent,
};
