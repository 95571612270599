const classnames = require('classnames');

const namespace = 'm';

const margins = (margin, className) => {
  if (!margin) {
    return className;
  }

  const { top, bottom, left, right } = margin;
  return classnames(
    {
      [`${namespace}t-${top}`]: top,
      [`${namespace}b-${bottom}`]: bottom,
      [`${namespace}l-${left}`]: left,
      [`${namespace}r-${right}`]: right,
    },
    className,
  );
};

module.exports = margins;
