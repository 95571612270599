const classnames = require('classnames');

const DEFAULT_FAMILY = false;

const namespace = 'font-family';

const familynames = (family, className) => {
  const familyName = typeof family === 'string' ? family : (family && family.font_family) || DEFAULT_FAMILY;
  return classnames({ [`${namespace}--${familyName}`]: familyName }, className);
};

module.exports = familynames;
